import request from '@/utils/request'
export default {
  //#region 医生排班
  getDoctorShiftBySearch: (data) => {
    return request({
      url: 'appointments/doctorShiftBySearch',
      method: 'POST',
      data,
    })
  },
  operationLog:(data)=> {
    return request({
      url: 'system/operationLog',
      method: 'POST',
      data
    }) 
  },
  //#endregion
}