<template>
  <a-dropdown v-model:visible="visible">
    <div class="lang-wrapper"><svg-icon icon="language" class="svgi" style="font-size: 24px;margin-right: 20px;"></svg-icon></div>
    <template #overlay>
      <a-menu @click="handleMenuClick">
        <a-menu-item @click="langClick('zh')" key="1">简体中文</a-menu-item>
        <a-menu-item @click="langClick('en')" key="2">English</a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { MenuInfo } from '@/Interfaces/user/user'
import { useI18n } from 'vue-i18n'
import store from '@/store'
export default defineComponent({
  setup(){
    const visible = ref(false)
    const i18n = useI18n()
    const handleMenuClick = (e: MenuInfo) => {
      if (e.key === '3') {
        visible.value = true
      }
    }
    const langClick = (lang: string) => {
      store.commit('app/changeLanguage', lang)
      i18n.locale.value = lang
    }
    return {
      handleMenuClick,langClick,visible
    }
  }
})

</script>
<style lang="scss" scoped>
.lang-wrapper {
  svg {
    width: 35px;
    height: 35px;
    padding: 5px;
    cursor: pointer;
    transition: background 0.5s;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
