<template>
  <div class="bridge-container">
    <slot><svg-icon class="svgi" icon="bell" style="font-size: 24px;"></svg-icon></slot>
    <div v-if="props.count>0" class="bridge-content">{{ props.count }}</div>
  </div>
</template>
<script lang="ts">
export default {
  name:'CBridge'
}
</script>
<script lang="ts" setup>
const props = defineProps<{
  count: number
}>()
</script>
<style scoped lang="scss">
.bridge-container {
  position: relative;

  .svgi {
      // width: 35px;
      // height: 35px;
      // padding:5px;
      cursor: pointer;
      transition: background 0.5s;
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  .bridge-content {
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #F44B2A;
    top: -4px;
    right: -4px;
    font-size: 12px;
    text-align: center;
    line-height: 18px;
    color: #fff;
  }
}
</style>