<template>
  <div class="theme-wrapper" @click="showModal">
    <svg-icon class="svgi" icon="change-theme" style="font-size: 24px;margin-right: 20px;"></svg-icon>
  </div>
  <a-modal
    v-model:visible="visible"
    title="选择主题颜色"
    @ok="handleOk"
    @cancel="handleCancle"
    @close="handleCancle"
  >
    <div style="margin: 10px; height: 30px">
      <div
        class="color-block"
        style="
          width: 20px;
          height: 20px;
          float: left;
          margin: 5px 5px;
          cursor: pointer;
        "
        @click="selectColor(item)"
        v-for="(item, index) in predefineColors"
        :key="index"
        :style="{ background: `${item}` }"
      ></div>
    </div>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { generateNewStyle } from '@/utils/theme'
import store from '@/store'
export default defineComponent({
  setup() {
    const visible = ref<boolean>(false)
    const showModal = () => {
      visible.value = true
    }
    // 预定义色值
    const predefineColors = ref<string[]>([
      '#ff4500',
      '#ff8c00',
      '#ffd700',
      '#90ee90',
      '#00ced1',
      '#1e90ff',
      '#c71585',
      'rgba(255, 69, 0, 0.68)',
      'rgb(255, 120, 0)',
      'hsl(181, 100%, 37%)',
      'hsla(209, 100%, 56%, 0.73)',
      '#c7158577',
      '#1890ff'
    ])
    const handleOk = () => {
      visible.value = false
    }
    const handleCancle = () => {
      visible.value = false
    }
    const selectColor = (color: string) => {
      const theme = {
        primaryColor: color,
        linkColor: color
      }      
      document.documentElement.style.setProperty('--activeColor', color);
      generateNewStyle(theme)
      store.commit('app/setTheme', theme)
    }
    return {
      handleOk,
      handleCancle,
      selectColor,
      showModal,
      visible,
      predefineColors
    }
  }
})
</script>
<style lang="scss" scoped>
.theme-wrapper {
  .svgi {
    width: 35px;
    height: 35px;
    padding:5px;
    cursor: pointer;
    transition: background 0.5s;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .color-wrapper {
    .color-block {
      width: 25px;
      height: 25px;
    }
  }
}
</style>
