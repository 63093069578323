<template>
  <div  @scroll="scrollEvent($event)" class="table-container"  style="border-right:solid 1px #eaeaea">
    <table ref="mainTable" class="main-table" @mouseleave="mouselevel">
      <tr v-if="props.tableTitle && columns">
        <th style="position:sticky;top:0" :colspan="columns.length">{{props.tableTitle}}</th>
      </tr>
      <tr v-if="props.showHeader">
        <th :class="[...getClassNames(col),'row-fixed', tdCenter ? 'td-center' : '']" 
        :style="cacColumnStyle(col,true,false)" v-for="(col,cindex) in cols" :key="cindex">{{ col.title }}</th>
      </tr>
      <template v-if="dataSource && dataSource.length>0 ">
        <tr  v-for="(row,index) in dataSource" @mouseover="handleMouseOver(index)" @click="selectItem(row,index)" :key="index">          
          <td :style="cacColumnStyle(col,false,false)" 
          :class="[...getClassNames(col),'ellipsis',rowStatus.overIndex==index ?'mover':'', rowStatus.selectedIndex==index?'selected':'', tdCenter ? 'td-center' : '']" 
          v-for="(col,cindex) in cols" :key="cindex">
            <slot v-if="col.slots" name="subcell" :column="col" :record="row" :rowCount="dataSource.length" :rowIndex="index" :slots="col.slots" 
            :extend="row.extendColumn"></slot>               
            <div v-else-if="col.dataIndex" :style="cacColumnStyle(col,false,true)" class="ellipsis">{{ row[col.dataIndex] }}</div>
          </td>
        </tr>
    </template>
    <template v-else>
      <tr>
        <td :colspan="cols.length" class="no-data">
        <div>
          <img src="@/assets/images/nodata.png" alt="">
          <div>沒有数据</div>
        </div>
        </td>
      </tr>
    </template>
    </table>
  </div>
</template>
<script lang="ts">
import { defineComponent,PropType,ref,watch ,Ref, computed} from "vue";
import {DataColumns, DataItem,Columns} from '@/Interfaces/user/DataItem';
interface TableDataItem extends DataItem {
  extendColumn?: { [key: string]: string[]};
  name:string,
  selectedIndex?:number
}
export default defineComponent({
  name:"SubTable",
  props:{  
    tableTitle:{
      type:String,
    },
    data: {
      type: Object as PropType<any[]|undefined>,
    },
    columns: {
      type: Object as PropType<DataColumns[]>,
    },
    styles:{
      type:Object,
      default:()=>{return {}}      
    },
    showHeader:{
      type:Boolean,
      default:false
    },
    windowWidth:{
      type:Number,
      default:1300
    },
    contentWidth:{
      type:Number,
      default:1800
    },
    headerStyle:{
      type:Object,
      default:()=>{return {'background-color':'#eef5f9'}}     
    },
    cellCenter:{
      type:Boolean,
      default:false
    },
    enableRowHover:{
      type:Boolean,
      default:true
    },
  },
  setup(props, { emit }) {
    
    const dSource=ref<any[]|undefined>(props.data as any);
    if(dSource.value){
      dSource.value.forEach((itm:any)=>{
        if(!itm.extendColumn)
          itm.extendColumn={}
        
      })
    }
      const dataSource = computed(() => {  
        return dSource.value      
      });
    
    watch(()=>props.data,()=>{
      dSource.value=props.data;
    })
    const tdCenter=computed(()=>props.cellCenter)
    const enableHover=computed(()=>props.enableRowHover)
    const cols=ref<Columns[]>([]);
    if(props.columns){
      for(let c of props.columns){
        cols.value.push({...c,fixedWidth:0})
      }
    }
    let tempWidth=0;
    let totalWidth=0;
    for(let i=0;i<cols.value.length;i++){
      if(cols.value[i].fixed=='left'){        
          tempWidth=cols.value[i].width as number      
          cols.value[i].fixedWidth=totalWidth
          totalWidth+=tempWidth;
      }
      //console.log(cols.value[i].fixedWidth)
    }
    const tableWidth=ref(props.contentWidth+'px')
    const winWidth=ref(props.windowWidth+'px')
    const mainTable=ref();
    let showLeftShadow=ref(false);
    let showRightShadow=ref(true);
    const rowStatus=ref({
      overIndex:-1,
      selectedIndex:-1
    });

    const handleMouseOver=(index:number)=>{
      if(enableHover.value){
      //console.log(enableHover.value)
      rowStatus.value.overIndex=index;
      }
    }
    const mouselevel =() =>{
      rowStatus.value.overIndex=-1
    }
    const selectItem=(item:TableDataItem,index:number)=>{
      rowStatus.value.selectedIndex=index
      emit("rowcick",item)
    }
    const getClassNames=(col)=>{ 
      //console.log(col)    
      let classNames:string[]=[];
      if(showLeftShadow.value && col.fixed=='left') classNames.push('shadow-left');
      if(showRightShadow.value && col.fixed=='right') classNames.push('shadow-right');
      if(col.fixed && col.fixed=='right') classNames.push('col-fixed-right');
      if(col.fixed && col.fixed=='left') classNames.push('col-fixed');
      return classNames;
    }
    const scrollEvent=(e)=>{    
        showRightShadow.value=e.target.scrollLeft<(props.contentWidth-props.windowWidth)
        showLeftShadow.value=e.target.scrollLeft>0
    }
    const cacColumnStyle=(col,isHeader,isdiv)=>{
      let style = {}
      if(col.width){
        style['width'] = col.width+'px'
      }
      if(col.fixed=='left' && !isdiv){
        style['left'] = col.fixedWidth+'px'
        if(isHeader){
          style['z-index']=4;
        //style['box-shadow']='0px 0px 5px #ccc'
        }
      }
      if(isHeader){
        if(props.headerStyle){   
          Object.keys(props.headerStyle).forEach((key)=>{
            style[key]=props.headerStyle[key]
          })          
        }
      }
      return style
    }
    return {
      emit,props,cacColumnStyle,scrollEvent,mouselevel,tdCenter,cols,rowStatus,mainTable,selectItem,handleMouseOver,
      showLeftShadow,tableWidth,winWidth,showRightShadow,getClassNames,dataSource
    }
  }
})
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/variables.module.scss';
.table-container{
  // width:v-bind(winWidth);
  width:calc(100vw - var(--sideBarWidth) - 10px);
  overflow: auto;
  border-left:solid 1px #eaeaea;
  border-bottom:solid 1px #eaeaea;
  .main-table{
    cursor:normal;
    min-width:v-bind(tableWidth);
    width:100%;
    table-layout:auto;
    border-collapse: separate;
    border-spacing:0;
    .no-data{
      text-align: center;
      color:#999999;
      font-size:14px;
      padding:0 20px;
      img{
        width:50px;
      }
    }
    th{
      // background:#eef5f9;

      color:#1a5e62;
      height:35px;
      line-height: 35px;
      padding-left:10px;
      text-align: left;
      font-weight: normal;
      border-top:solid 1px #eaeaea;
      border-bottom:solid 1px #eaeaea;
      position: sticky;
      top:0;
    }
    tr{
      height:30px;
      background:#eaeaea;
      line-height: 39px;
    }
    td{
      color:#666666;
      // border-left:solid 1px red;
      height:35px;
      font-size:14px;
      line-height: 35px;
      // text-align: center;
      background:white;
      border-bottom:solid 1px #eaeaea;
      div{
        padding:0 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .td-center{
      padding-left:0 !important;
      text-align: center;
    }
    .col-fixed{
      left:0px;
      position:sticky;
      z-index:2;
      
    }
    .col-fixed-right{
      right:0px;
      position:sticky;
      z-index:2;
      
    }
    .row-fixed{
      top:0px;
      position:sticky;
      z-index:3;
    }
    .mover{
      background:#e5f2f2;
    }
    .selected{
      background:#d9e1f4;
    }
  }
}
.shadow-left {
  box-shadow: 3px 0 3px rgba(0, 0, 0, 0.1);
}
.shadow-right {
  box-shadow: -3px 0 3px rgba(0, 0, 0, 0.1);
}
.hightlight{
  background:yellow;
  color:red;
}
.ellipsis {  
  white-space: nowrap; /* 防止文本换行 */  
    overflow: hidden; /* 隐藏超出容器的内容 */  
    text-overflow: ellipsis; /* 用省略号表示溢出的文本 */   
}
</style>