import axios from 'axios'
import { isTokenTimeout } from './auth'
import { notification } from 'ant-design-vue'
import store from '@/store'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 20000
})
service.interceptors.request.use(
  (config) => {
    // if(config.headers){
    //     config.headers.icode='978D058300C262E2'
    // }
    // console.log(config.url, 'config')
    if (store.getters.token) {
      // if (config.url == 'appointments/uploadImage') {
      //   config.headers['Content-Type'] = 'multipart/form-data'
      // }
      // config.headers.Authorization = `Bearer ${store.getters.token}`
      config.headers.token = store.getters.token
      // config.headers['Content-Type'] = 'application/json; charset=utf-8'
      if (isTokenTimeout()) {
        store.dispatch('user/logout')
        notification.open({
          message: '登录超时',
          description: '登录超时，请重新登录。'
        })
        return Promise.reject(new Error('TOKEN已过期。'))
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error.response.data)
  }
)
service.interceptors.response.use(
  (response) => {
    const { success, message, data } = response.data
    if (success) {
      return response.data
    } else {
      return Promise.reject(new Error(message))
    }
  },
  (error) => {
    if (
      error.response &&
      error.response.data &&
      (error.response.data.code === 401 || error.response.data.code === 8)
    ) {
      store.dispatch('user/logout')
      notification.open({
        message: '登录超时',
        description: '登录超时，请重新登录。'
      })
    }
    return Promise.reject(error.response.data)
  }
)
export default service
