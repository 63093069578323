export default interface AuditSetting{
  //1版型审核，2面料审核，3饰品审核
  id: number,
  auditor: number,
  orgId:number,
  auditorName: string,
  rolename: string,
  auditIndex:number ,
  status: number,
  auditType: EnumAuditType,
  createTime: number
}
export enum EnumAuditType{
  版型审核 = 1,
  面料审核 = 2,
  饰品审核 = 3,
  共享审核 = 4
}
export enum OrgType{
  蓝迪云平台=1,
  分店=2,
  面料商=3,
  入驻版型师=4,
  成衣生产商=5
}