
import { defineComponent,PropType,ref,watch ,Ref, computed} from "vue";
import {DataColumns, DataItem,Columns} from '@/Interfaces/user/DataItem';
interface TableDataItem extends DataItem {
  extendColumn?: { [key: string]: string[]};
  name:string,
  selectedIndex?:number
}
const __default__ = defineComponent({
  name:"SubTable",
  props:{  
    tableTitle:{
      type:String,
    },
    data: {
      type: Object as PropType<any[]|undefined>,
    },
    columns: {
      type: Object as PropType<DataColumns[]>,
    },
    styles:{
      type:Object,
      default:()=>{return {}}      
    },
    showHeader:{
      type:Boolean,
      default:false
    },
    windowWidth:{
      type:Number,
      default:1300
    },
    contentWidth:{
      type:Number,
      default:1800
    },
    headerStyle:{
      type:Object,
      default:()=>{return {'background-color':'#eef5f9'}}     
    },
    cellCenter:{
      type:Boolean,
      default:false
    },
    enableRowHover:{
      type:Boolean,
      default:true
    },
  },
  setup(props, { emit }) {
    
    const dSource=ref<any[]|undefined>(props.data as any);
    if(dSource.value){
      dSource.value.forEach((itm:any)=>{
        if(!itm.extendColumn)
          itm.extendColumn={}
        
      })
    }
      const dataSource = computed(() => {  
        return dSource.value      
      });
    
    watch(()=>props.data,()=>{
      dSource.value=props.data;
    })
    const tdCenter=computed(()=>props.cellCenter)
    const enableHover=computed(()=>props.enableRowHover)
    const cols=ref<Columns[]>([]);
    if(props.columns){
      for(let c of props.columns){
        cols.value.push({...c,fixedWidth:0})
      }
    }
    let tempWidth=0;
    let totalWidth=0;
    for(let i=0;i<cols.value.length;i++){
      if(cols.value[i].fixed=='left'){        
          tempWidth=cols.value[i].width as number      
          cols.value[i].fixedWidth=totalWidth
          totalWidth+=tempWidth;
      }
      //console.log(cols.value[i].fixedWidth)
    }
    const tableWidth=ref(props.contentWidth+'px')
    const winWidth=ref(props.windowWidth+'px')
    const mainTable=ref();
    let showLeftShadow=ref(false);
    let showRightShadow=ref(true);
    const rowStatus=ref({
      overIndex:-1,
      selectedIndex:-1
    });

    const handleMouseOver=(index:number)=>{
      if(enableHover.value){
      //console.log(enableHover.value)
      rowStatus.value.overIndex=index;
      }
    }
    const mouselevel =() =>{
      rowStatus.value.overIndex=-1
    }
    const selectItem=(item:TableDataItem,index:number)=>{
      rowStatus.value.selectedIndex=index
      emit("rowcick",item)
    }
    const getClassNames=(col)=>{ 
      //console.log(col)    
      let classNames:string[]=[];
      if(showLeftShadow.value && col.fixed=='left') classNames.push('shadow-left');
      if(showRightShadow.value && col.fixed=='right') classNames.push('shadow-right');
      if(col.fixed && col.fixed=='right') classNames.push('col-fixed-right');
      if(col.fixed && col.fixed=='left') classNames.push('col-fixed');
      return classNames;
    }
    const scrollEvent=(e)=>{    
        showRightShadow.value=e.target.scrollLeft<(props.contentWidth-props.windowWidth)
        showLeftShadow.value=e.target.scrollLeft>0
    }
    const cacColumnStyle=(col,isHeader,isdiv)=>{
      let style = {}
      if(col.width){
        style['width'] = col.width+'px'
      }
      if(col.fixed=='left' && !isdiv){
        style['left'] = col.fixedWidth+'px'
        if(isHeader){
          style['z-index']=4;
        //style['box-shadow']='0px 0px 5px #ccc'
        }
      }
      if(isHeader){
        if(props.headerStyle){   
          Object.keys(props.headerStyle).forEach((key)=>{
            style[key]=props.headerStyle[key]
          })          
        }
      }
      return style
    }
    return {
      emit,props,cacColumnStyle,scrollEvent,mouselevel,tdCenter,cols,rowStatus,mainTable,selectItem,handleMouseOver,
      showLeftShadow,tableWidth,winWidth,showRightShadow,getClassNames,dataSource
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "bd380a9e": (_ctx.tableWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__