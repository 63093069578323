<template>
  <div v-if="!isvalid" class="validator">  
    <a-tooltip overlayClassName="nameTooltipClass" placement="topLeft">
      <template v-if="err" #title>{{err}}</template>  
    <slot></slot>
    </a-tooltip>
    <!-- <div class="label">{{ err }}</div> -->
  </div>
  <slot v-else></slot>
</template>
<script lang="ts">
import { defineComponent,watch,computed} from "vue";
export default defineComponent({
  name:'Validator',
  props:{  
    isValid: {
      type: Boolean,
      required: true,
      default:true
    },
    error: {
      type: String
    },

  },
  setup(props) {   
    const isvalid=computed(()=>{
      return props.isValid
    })
    const err=computed(()=>{
      return props.error

    })
    return {
      err,isvalid
    }
  }
})
</script>
<style lang="scss" scoped>

  .validator  :deep(.ant-select-selector){
    border:solid 1px red;
  }
  .validator  :deep(.c-input-box){
    border:solid 1px red;
  }
  .validator  :deep(.ant-picker){
    border:solid 1px red;
  }
  .validator  :deep(.ant-input-group-wrapper){
    border:solid 1px red;
  }
  .validator{
    position:relative;
    .label{
      color:red;
      font-size:12px;
      position:absolute;
      top:5px;
      left:5px;
      
    }
    // &:hover{
    //     .label{
    //       display:none;
    //     }
    // }
  }


</style>