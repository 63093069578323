import router from '@/router'
import store from '@/store'
/**
 * 免登录白名单
 */
const whiteList:string[] = ['/login','/basy']
/**
 * 路由前置处理
 */
router.beforeEach(async (to, from, next) => {
  if (store.getters.token) {
    if (to.path === '/login') {
      next('/')
    } else {
      next()
    }
    if(!store.getters.hasUserInfo){
        await store.dispatch('user/getUserInfo')

    }
  } else {
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      next('/login')
    }
  }
})
