<template>
  <div id="appmain" class="app-main">
      <router-view v-slot="{ Component, route }">
        <transition name="fade" mode="out-in">    
          <keep-alive v-if="route.meta.keepAlive">
            <component :is="Component" :key="route.path" />  
          </keep-alive>
          <component v-else :is="Component" :key="route.path" /> 
        </transition>
      </router-view>
   
    <!-- <router-view v-else v-slot="{ Component, route }">
      <transition name="fade" mode="out-in">        
          <component :is="Component" :key="route.path" />
      </transition>
    </router-view> -->
  </div>
</template>
<script lang="ts">
import { useRoute, RouteMeta } from 'vue-router'
import { defineComponent, watch } from 'vue'
import { generateTitle } from '@/utils/lang'
import store from '@/store'
export default defineComponent({
  setup() {
    const route = useRoute()
    const getTitle = (meta: RouteMeta): string => {
      if (meta) {
        return generateTitle(meta.title)
      } else {
        return ''
      }
    }
    watch(route, (from, to) => {
      const { fullPath, meta, name, params, path, query } = to
      store.commit('app/addTagView', {
        fullPath,
        meta,
        name,
        params,
        path,
        query,
        title: getTitle(meta)
      })
    })
    return {
      route
    }
  }
})

</script>
<style lang="scss" scoped>
@import '~@/assets/styles/variables.module.scss';

.app-main {
  // min-height: calc(100vh - 90px);
  // height: 100vh;
  // height: 100%;
  // width: 100%;
  position: relative;
  // padding: 0 10px;
  box-sizing: border-box;
  // padding-top: $fixedHeaderHeight;
  overflow-y: auto;
  // background:#edf1f5;
  height: calc(100vh - #{$fixedHeaderHeight});
}

.fade-enter-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  // transform: translateX(20px);
  opacity: 0;
}
</style>
