import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e407d042"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "validator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tooltip = _resolveComponent("a-tooltip")!

  return (!_ctx.isvalid)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_a_tooltip, {
          overlayClassName: "nameTooltipClass",
          placement: "topLeft"
        }, _createSlots({
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 2
        }, [
          (_ctx.err)
            ? {
                name: "title",
                fn: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.err), 1)
                ]),
                key: "0"
              }
            : undefined
        ]), 1024)
      ]))
    : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
}