<template>
  <div class="breadcrumb-container">
    <div class="back-icon">
      <svg-icon @click="goback" icon="prev"></svg-icon>
    </div>
    <a-breadcrumb>
      <!-- <transition-group name="breadcrumb"> -->
      <a-breadcrumb-item v-for="path in breadPath" :key="path.path">
        <router-link v-if="path.path !== currentPath" :to="path.path">
          {{ generateTitle(path.meta.title) }}
        </router-link>
        <span v-else>{{ generateTitle(path.meta.title) }}</span>
      </a-breadcrumb-item>
      <!-- </transition-group> -->
    </a-breadcrumb>
  </div>
</template>
<script lang="ts">
import { useRoute, RouteLocationMatched } from 'vue-router'
import { watch, ref, defineComponent } from 'vue'
import { generateTitle } from '@/utils/lang'
import { isNull } from '@/utils/route'
export default defineComponent({
setup(){
  const breadPath = ref<RouteLocationMatched[]>([])
  const route = useRoute()
  breadPath.value = route.matched.filter((r) => !isNull(r.meta))

  const currentPath = ref('')
  const goback = () => {
    window.history.back()
  }
  watch(route, () => {
    breadPath.value = route.matched.filter((r) => !isNull(r.meta))
    currentPath.value = route.path
  })
  return {
    generateTitle,currentPath,breadPath,goback
  }
}
})

</script>
<style lang="scss" scoped>
.breadcrumb-container {
  display:flex;
  align-items:center;
  .back-icon{
    cursor: pointer;
    width:25px;
    height:30px;
    transition: background 0.5s;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .ant-breadcrumb{
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
  }
}
</style>
