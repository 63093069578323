<template>
  <div class="icon-container" @click="iconclick">
    <svg-icon :icon="icon"></svg-icon>
  </div>
</template>
<script lang="ts">
import store from '@/store'
import { computed, defineComponent, ref } from 'vue'
export default defineComponent({
  setup(){
    const iconclick = () => {
      //sideBarWidth.value=store.getters.sideBarOpened?210:80
      store.commit('app/openSidebar')
      
    }
    const icon = computed(() => {
      return store.getters.sideBarOpened ? 'hamburger-opened' : 'hamburger-closed'
    })
    return {
      iconclick,icon
    }
  }
})

</script>
<style lang="scss" scoped>

.icon-container {
  cursor: pointer;
  padding: 0 5px;
  // width: 25px;
  font-size: 24px;
  transition: background 0.5s;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
