
import { defineComponent,PropType,ref,watch ,Ref, computed} from "vue";
import {DataColumns, DataItem,Columns,SubTableOption} from '@/Interfaces/user/DataItem';
import SubTable from './SubTable.vue'
interface TableDataItem extends DataItem {
  extendColumn?: { [key: string]: string[]};
  name:string,
  selectedIndex?:number,
  subrowStatus?:{selectedIndex:number,overIndex:number}    
}
const __default__ = defineComponent({
  name:"FugleTable",
  components:{
    SubTable
  },
  props:{  
    tableTitle:{
      type:String,
    },
    data: {
      type: Object as PropType<any[]>,
      required: true
    },
    height:{
      type:String,

    },
    showSubItems:{
      type:Boolean,
      default:false
    },
    columns: {
      type: Object as PropType<DataColumns[]>,
      required: true
    },
    subColumns: {
      type: Object as PropType<DataColumns[]>,
    },
    styles:{
      type:Object,
      default:()=>{return {}}      
    },
    searchColumn:{
      type:Object as PropType<string[]>,
        required: true
    },
    windowWidth:{
      type:Number,
      default:1300
    },
    contentWidth:{
      type:Number,
      default:1800
    },
    searchText:{
      type:Object as PropType<Ref<string>>,
        required: true
    },
    headerStyle:{
      type:Object,
      default:()=>{return {'background-color':'#eef5f9'}}     
    },
    cellCenter:{
      type:Boolean,
      default:false
    },
    enableRowHover:{
      type:Boolean,
      default:true
    },
    pageSize:{
      type:Number,
      default:50
    },
    subTable:{
      type:Object as PropType<SubTableOption>
    },
    showSelected:{
      type:Boolean,
      default:true
    }
  },
  setup(props, { emit }) {
    
    const dSource=ref<any[]>(props.data);
      dSource.value.forEach((itm:any)=>{
        if(!itm.extendColumn)
          itm.extendColumn={}
        if(props.searchColumn){
          for(let col of props.searchColumn){  
            if(props.searchText){
              itm.extendColumn[col]= [itm[col]]
            }
          }
        }
      })
      const subRowClick =(row)=>{
        console.log(row)
        emit('subRowClick',row)
      }
      const expandClick=(row)=>{
        if(row.expand && row.expand==true){
          row.expand=false
        }else{
          row.expand=true
        }
      }
      const dataSource = computed(() => {  
        const searchText = props.searchText?.value;  
        const searchColumns = props.searchColumn;  
        
        if (!searchText || searchColumns.length === 0) {
          if(dSource.value){  
            return dSource.value.slice(0,props.pageSize)
          }else{
            return []
          }
        }  
        
        let rrs= dSource.value.filter(itm => {  
          return searchColumns.some(column => {  
            const itemValue = itm[column];  
            return itemValue && itemValue.indexOf(searchText) >= 0;  
          });  
        });  
        return rrs.slice(0,props.pageSize)
      });
    watch(()=>props.data,()=>{
      dSource.value=props.data;
    })
    watch(() => props.searchText, () => {
      if(props.searchText){        
      dataSource.value.forEach((itm:TableDataItem)=>{
          if(!itm.extendColumn)
            itm.extendColumn={}
          if(props.searchColumn){
            for(let col of props.searchColumn){  
              if(props.searchText && itm[col]){
                itm.extendColumn[col]= itm[col].toString().split(new RegExp(`(?<=${props.searchText.value})|(?=${props.searchText.value})`, 'i'))
                //console.log(itm.extendColumn)
              }
            }
          }
        }) 
      }
    })
    const tdCenter=computed(()=>props.cellCenter)
    const enableHover=computed(()=>props.enableRowHover)
    const cols=ref<Columns[]>([]);
    const subcols=ref<Columns[]>([]);
    for(let c of props.columns){
      cols.value.push({...c,fixedWidth:0})
    }
    if(props.subColumns){
      for(let c of props.subColumns){
        subcols.value.push({...c,fixedWidth:0})
      }
    }
    let tempWidth=0;
    let totalWidth=0;
    for(let i=0;i<cols.value.length;i++){
      if(cols.value[i].fixed=='left'){        
          tempWidth=cols.value[i].width as number      
          cols.value[i].fixedWidth=totalWidth
          totalWidth+=tempWidth;
      }
    }
    tempWidth=0;
    totalWidth=0;
    for(let i=0;i<subcols.value.length;i++){
      if(subcols.value[i].fixed=='left'){        
          tempWidth=subcols.value[i].width as number      
          subcols.value[i].fixedWidth=totalWidth
          totalWidth+=tempWidth;
      }
    }
    const tableWidth=ref(props.contentWidth+'px')
    const winWidth=ref(props.windowWidth+'px')
    const mainTable=ref();
    let showLeftShadow=ref(false);
    let showRightShadow=ref(true);
    const rowStatus=ref({
      overIndex:-1,
      selectedIndex:-1
    });
    const subRowStatus=ref({
      overIndex:-1,
      selectedIndex:-1
    });
    const handleMouseOver=(index:number)=>{
      if(enableHover.value){
      //console.log(enableHover.value)
      rowStatus.value.overIndex=index;
      }
    }
    const mouselevel =() =>{
      rowStatus.value.overIndex=-1
    }
    const selectItem=(item:TableDataItem,index:number)=>{
      rowStatus.value.selectedIndex=index
      emit("rowcick",item)
    }

    const subhandleMouseOver=(row:TableDataItem, index:number)=>{
      if(enableHover.value){
      //console.log(enableHover.value)
      subRowStatus.value.overIndex=index;
      if(!row.subrowStatus){
        row.subrowStatus={selectedIndex:-1,overIndex:index}
      }
      row.subrowStatus.overIndex=index
      }
    }
    const submouselevel =(item:TableDataItem) =>{
      subRowStatus.value.overIndex=-1
      if(!item.subrowStatus){
        item.subrowStatus={selectedIndex:-1,overIndex:-1}
      }
      item.subrowStatus.overIndex=-1
    }
    const subselectItem=(row,item:TableDataItem,index:number)=>{
      if(!row.subrowStatus){
        row.subrowStatus={selectedIndex:index,overIndex:-1}
      }
      row.subrowStatus.selectedIndex=index
      emit("rowcick",item)
    }
    const getClassNames=(col)=>{ 
      //console.log(col)    
      let classNames:string[]=[];
      if(showLeftShadow.value && col.fixed=='left') classNames.push('shadow-left');
      if(showRightShadow.value && col.fixed=='right') classNames.push('shadow-right');
      if(col.fixed && col.fixed=='right') classNames.push('col-fixed-right');
      if(col.fixed && col.fixed=='left') classNames.push('col-fixed');
      return classNames;
    }
    const scrollEvent=(e)=>{    
        showRightShadow.value=e.target.scrollLeft<(props.contentWidth-props.windowWidth)
        showLeftShadow.value=e.target.scrollLeft>0
    }
    const cacColumnStyle=(col,isHeader,isdiv,index)=>{
      let style = {}
      if(col.width){
        style['width'] = col.width+'px'
      }
      if(index==0 && props.showSubItems){
        style['padding-left']='0'
      }
      if(col.fixed=='left' && !isdiv){
        style['left'] = col.fixedWidth+'px'
        if(isHeader){
          style['z-index']=4;
        //style['box-shadow']='0px 0px 5px #ccc'
        }
      }
      if(isHeader){
        if(props.headerStyle){   
          Object.keys(props.headerStyle).forEach((key)=>{
            style[key]=props.headerStyle[key]
          })          
        }
      }
      
      return style
    }
    const subcacColumnStyle=(col,isHeader,isdiv,index)=>{
      let style = {}
      if(col.width){
        style['width'] = col.width+'px'
      }
      if(index==0){
        style['padding-left']='10px'
      }
      if(col.fixed=='left' && !isdiv){
        style['left'] = col.fixedWidth+'px'
        if(isHeader){
          style['z-index']=4;
        //style['box-shadow']='0px 0px 5px #ccc'
        }
      }
      if(isHeader){
        if(props.headerStyle){   
          Object.keys(props.headerStyle).forEach((key)=>{
            style[key]=props.headerStyle[key]
          })          
        }
      }
      
      return style
    }
    const baseStyle=ref({
      ...props.styles,
      height:props.height?props.height:'unset'
    })
    watch(  
      () => props.height,  
      (newValue, oldValue) => {  
        if (newValue) {  
          baseStyle.value.height = newValue;  
        }  
      }  
    ); 
    return {
      emit,props,cacColumnStyle,ref,subRowStatus,baseStyle,subRowClick,scrollEvent,mouselevel,tdCenter,cols,
      subhandleMouseOver,submouselevel,subselectItem,subcacColumnStyle,expandClick,subcols,
      rowStatus,mainTable,selectItem,handleMouseOver,showLeftShadow,tableWidth,winWidth,showRightShadow,getClassNames,dataSource
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "18411cb8": (_ctx.tableWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__