import { StateData, Theme } from '@/Interfaces/user/user'
import { setItem, getItem } from '@/utils/storege'
import { LANG, THEME, DEFAULT_THEME, TAGS_VIEW,LEFT_SIDE_MAX_WIDTH,LEFT_SIDE_MIN_WIDTH } from '@/constants'
import sysApi from '@/api/sys'
import appApi from '@/api/business'
export default {
  namespaced: true,
  state: {
    sideBarOpened: getItem("sideBarOpened")=="1",
    language: getItem(LANG) || 'zh',
    theme: getItem(THEME) || DEFAULT_THEME,
    tagsViewList: getItem(TAGS_VIEW) || [],
    onmessage:null,
    onsysmessage:null,
    socket:null,
  },
  mutations: {
    openSidebar(state: StateData) {
      state.sideBarOpened = !state.sideBarOpened
      document.body.style.setProperty('--sideBarWidth', state.sideBarOpened ? LEFT_SIDE_MAX_WIDTH+'px' : LEFT_SIDE_MIN_WIDTH+'px')
      setItem('sideBarOpened', state.sideBarOpened?"1":"0")
    },
    changeLanguage(state: StateData, lang: string) {
      state.language = lang
      setItem(LANG, lang)
    },
    setTheme(state: StateData, theme: Theme) {
      state.theme = theme
      setItem(THEME, theme)
    },
    addTagView(state: StateData, tag: any) {
      const existsTag = state.tagsViewList.find(item => {
        return item.path == tag.path
      })
      if (!existsTag) {
        state.tagsViewList.push(tag)
        setItem(TAGS_VIEW, state.tagsViewList)
      }
    },
    removeTagView(state: StateData, tag: any) {
      for (let i = 0; i < state.tagsViewList.length; i++) {
        if (state.tagsViewList[i].path === tag.path) {
          state.tagsViewList.splice(i, 1)
          break;
        }
      }
      setItem(TAGS_VIEW, state.tagsViewList)
    }
  },
  actions: {
    async insertSysMessages(context, data) {
      const res = await sysApi.insertSysMessages(data)
      return res
    },

  }
}
