<template>
  <div class="c-divider">
    <span class="c-divider-text">
      <slot></slot>
    </span>
  </div>
</template>
<script lang="ts">
export default {
  name:"CDivider"
}
</script>
<script lang="ts" setup>
import { ref, watchEffect } from 'vue';
const props = withDefaults(defineProps<{ showLine: boolean }>(), {
  showLine: true
})
let lineWidth = ref('');
watchEffect(() => {
  lineWidth.value = props.showLine ? '80%' : '0px';
})
</script>
<style scoped lang="scss">
@import '~@/assets/styles/variables.module.scss';
.c-divider {
  position: relative;
  margin: 15px 0;
  font-size: 16px;

  .c-divider-text {
    display: inline-block;
    padding: 0 15px;
  }

  &:before {
    position: absolute;
    display: inline-block;
    content: '';
    width: 4px;
    height: 20px;
    background-color: $activeColor;
    top: 4px;
    left: 3px;
  }

  &::after {
    position: absolute;
    display: inline-block;
    content: '';
    width: v-bind(lineWidth);
    height: 1px;
    background-color: #f7f7f7;
    top: 12px;
  }
}
</style>