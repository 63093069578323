<template>
  <div>
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>  
</template>
<script lang="ts">
import { computed, defineComponent, onMounted,ref } from 'vue'
import { useStore } from 'vuex'
import { generateNewStyle } from '@/utils/theme'
import { LEFT_SIDE_MAX_WIDTH,LEFT_SIDE_MIN_WIDTH } from '@/constants'
import store from '@/store'
// import locale from '@/i18n/lang/zh'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import enGB from 'ant-design-vue/lib/locale-provider/en_GB';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

export interface Operator {
  Name: string
  Age: number
}
export default defineComponent({
  setup() {
    const store = useStore()
    const person: Operator = {
      Name: 'jax ',
      Age: 34
    }
    const reconnectInterval = 5000;
    const socketRef = ref<WebSocket | null>(null);
    const initWebSocket = () => {
      const wsfapi = process.env.VUE_APP_BASE_WS + 'org' + store.getters.userInfo.id;
      socketRef.value = new WebSocket(wsfapi);
      socketRef.value.addEventListener('open', () => {
        console.log('ws connected');
      });
      socketRef.value.addEventListener('message', (event) => {
        console.log(event.data)
        if(store.getters.onmessage){         
          store.getters.onmessage(event)
        }
        if(store.getters.onsysmessage){
          store.getters.onsysmessage(event)
        }
      });

      // 关键：处理断开连接事件，触发重连
      socketRef.value.addEventListener('close', () => {
        console.log('WebSocket disconnected. Reconnecting in ' + reconnectInterval / 1000 + ' seconds...');
        setTimeout(initWebSocket, reconnectInterval); // 设置定时器进行重连
      });

      socketRef.value.addEventListener('error', (error) => {
        console.error('WebSocket error:', error);
      });
      store.state.app.socket=socketRef.value
    };
    const lang=computed(() => {
      if(store.getters.language=='zh'){
        dayjs.locale('zh-cn');
        return zhCN
      }else{
        dayjs.locale('en-gb');
        return enGB
      }

    })
    onMounted(() => {
      document.documentElement.style.setProperty('--activeColor', store.getters.theme.primaryColor);
      document.body.style.setProperty('--sideBarWidth', store.state.app.sideBarOpened ? LEFT_SIDE_MAX_WIDTH+'px' : LEFT_SIDE_MIN_WIDTH+'px')
      initWebSocket()
      generateNewStyle(store.getters.theme)
      
    })
    return { person, locale:lang }
  }
})
</script>
<style lang="scss">
#app {
  overflow: hidden;
}
</style>
