import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-753830bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "siderbar-menu" }
const _hoisted_2 = { class: "org-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_menu, {
      mode: "inline",
      theme: "dark",
      "inline-collapsed": !_ctx.store.getters.sideBarOpened,
      openKeys: _ctx.openKeys,
      "onUpdate:openKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openKeys) = $event)),
      selectedKeys: _ctx.selectedKeys,
      "onUpdate:selectedKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedKeys) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (menu.children && menu.children.length>0)
              ? (_openBlock(), _createBlock(_component_a_sub_menu, {
                  key: menu.path
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_svg_icon, {
                      class: _normalizeClass([_ctx.store.getters.sideBarOpened?'menu-icon':'plus-menu-icon']),
                      icon: menu.icon
                    }, null, 8, ["class", "icon"])
                  ]),
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(menu.title), 1)
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.children, (submenu, sindex) => {
                      return (_openBlock(), _createBlock(_component_a_menu_item, {
                        onClick: ($event: any) => (_ctx.menuClick(submenu,'ss'+sindex)),
                        key: submenu.path
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(submenu.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024))
              : (_openBlock(), _createBlock(_component_a_menu_item, {
                  key: 'ss'+menu.path,
                  onClick: ($event: any) => (_ctx.menuClick(menu,'k'+index))
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_svg_icon, {
                      class: _normalizeClass([_ctx.store.getters.sideBarOpened?'menu-icon':'plus-menu-icon']),
                      icon: menu.icon
                    }, null, 8, ["class", "icon"])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(menu.title), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
          ], 64))
        }), 256))
      ]),
      _: 1
    }, 8, ["inline-collapsed", "openKeys", "selectedKeys"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, _toDisplayString(_ctx.userInfo.orgName), 1),
      _createElementVNode("div", null, "(" + _toDisplayString(_ctx.userInfo.roleName) + ")", 1)
    ])
  ]))
}