<template>
  <div class="wraper">
    <div class="logo">
      <img v-if="store.getters.sideBarOpened"  src="@/assets/images/logo-white.png" alt="" />
      <img v-else src="@/assets/images/logo-short.png" alt="">
      <!-- <span v-if="store.getters.sideBarOpened">万达信息</span> -->
    </div>
    <menu-bar></menu-bar>
  </div>
</template>
<script lang="ts">
import MenuBar from './MenuBar.vue'
import store from '@/store'
import { defineComponent } from 'vue'
export default defineComponent({
  components: {
    'menu-bar': MenuBar,
  },
  setup(){
    const logoHeight = 40
    return {
      store,logoHeight
    }
    
  }
})

</script>
<style lang="scss" scoped>
.wraper {
  .logo {
    display: flex;
    align-items: center;
    margin-left: 20px;
    color: white;
    height: 70px;
    overflow: hidden;

    img {
      height: 50px;
      margin: 0 5px;
    }

    span {
      font-size: 21px;
      font-weight: 600;
      margin-left: 10px;
    }

  }
}
</style>
