import businessApi from "@/api/business"
export default {
  namespaced: true,
  actions: {
    async getDoctorShiftBySearch(context, data) {
      const res = await businessApi.getDoctorShiftBySearch(data)
      return res
    },
    
  }
}