import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54c2feb5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "appmain",
  class: "app-main"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component, route }) => [
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (route.meta.keepAlive)
              ? (_openBlock(), _createBlock(_KeepAlive, { key: 0 }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                    key: route.path
                  }))
                ], 1024))
              : (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  key: route.path
                }))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    })
  ]))
}